@import "@/assets/scss/style.scss";

.wrapper {
    padding-top: 8px;
    padding-left: 24px;
    margin-bottom: 16px;

    & > button {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
}
