@import "@/assets/scss/style.scss";

.flowchart {
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.4);

    &::v-deep {
        #position {
            display: none;
        }

        .unconnectable {
            .connector {
                display: none;
            }
        }
    }
}
